<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <v-card elevation="1" class="pt-8 mt-4 mb-4 pa-4">
          <v-row>
            <v-col cols="4">
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dataInicio"
                    placeholder="Data Inicial"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Inicial"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.dataInicio"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="formData.dataTermino"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuDataInicio = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuDataInicio = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataTermino"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dataTermino"
                    placeholder="Data Final"
                    outlined
                    dense
                    :clearable="true"
                    label="Data Final"
                    required
                    :rules="rules.genericRules"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.dataTermino"
                  no-title
                  scrollable
                  locale="pt-br"
                  :max="nowDate"
                  :min="formData.dataInicio"
                  color="black"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuDataTermino = false">
                    Fechar
                  </v-btn>
                  <v-btn text color="primary" @click="menuDataTermino = false">
                    Selecionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-btn
                depressed
                color="green accent-4"
                dark
                :loading="loadingBtn"
                block
                @click="getSolicitacoesDownload()"
                >EXPORTAR XLS</v-btn
              ></v-col
            >
          </v-row></v-card
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { solicitacoesExel } from "@/services/relatorio";
import { rules } from "@/utils/rules.js";
export default {
  name: "RelatorioExportarSolicitacoes",
  components: {
  },
  data: () => ({
    rules: rules,
    breadcrumbs: [
      {
        text: "Relatórios",
        disabled: false,
        to: "",
      },
      {
        text: "Exportar Solicitações",
        disabled: true,
        to: "",
      },
    ],
    nowDate: getNowDate(),
    firstDateOfYear: getFirstDateOfYear(),
    panel: [0],
    formData: {
      dataInicio: "",
      dataTermino: "",
    },
    menuDataInicio: false,
    menuDataTermino: false,
    loadingBtn: false,
  }),
  created() {
    this.formData.dataInicio = this.firstDateOfYear;
    this.formData.dataTermino = this.nowDate;
  },
  methods: {
    async getSolicitacoesDownload() {
      this.loadingBtn = true;
      try {
        const resp = await solicitacoesExel(this.formData);
        window.open(resp.data.url, "_blank");
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        console.error(e);
      }
    },
    setDate(date) {
      const newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
  },
};

function getFirstDateOfYear() {
  const now = new Date();
  const firstDateOfYear = new Date(now.getFullYear(), 0, 1);
  return firstDateOfYear.toISOString().slice(0, 10);
}

function getNowDate() {
  const now = new Date();  
  const saoPauloOffset = -3 * 60;
  const localOffset = now.getTimezoneOffset();
  const offsetDifference = saoPauloOffset - localOffset;
  const saoPauloTime = new Date(now.getTime() + offsetDifference * 60 * 1000);
  const year = saoPauloTime.getFullYear();
  const month = String(saoPauloTime.getMonth() + 1).padStart(2, '0');
  const day = String(saoPauloTime.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
</script>

<style></style>
